<template>
	<v-footer
			class="transparent pt-3 pb-2"
			:style="{filter: $vuetify.theme.themes.style ? $vuetify.theme.themes.style.filter : '' }"
	>
		<v-spacer
				v-if="sidebar"
				class="transition-cubic"
				:style="!drawer_market || !drawer_dash ? {} : drawer_mini ? {width: '56px'} : $route.path.match('/auth') ? {} : {width: '256px'}"
		/>
		<div
				class="caption-1"
				:class="sidebar ? '':'text-center'"
				:style="sidebar? {}:{width: '100%'}"
		>&copy; {{ new Date().getFullYear() }} &mdash; <img style="position: relative; top: 4px; margin-right: 4px" width="80px" :src="$vuetify.theme.dark ? require('@/assets/logo_dark.svg') : require('@/assets/logo_light.svg')" /> {{ $tc('single_trad.Footer', 1) }} <img style="position: relative; top: 4px; margin-left: 4px" width="66px" :src="$vuetify.theme.dark ? require('@/assets/logo_color_black.svg') : require('@/assets/logo_color.svg')" /></div>
		<v-spacer
				class="transition-cubic"
				:style="!drawer_item ? {} : {width: '256px'}"
		/>
	</v-footer>
</template>

<script>
	export default {
		name: 'Footer',
		computed: {
			drawer_mini(){
				return this.$store.getters['dashboard/drawer_mini']
			},
			drawer_market(){
				return this.$store.getters['marketplace/drawer']
			},
			drawer_dash(){
				return this.$store.getters['dashboard/drawer']
			},
			drawer_item(){
				return this.$store.getters['dashboard/drawer_item']
			},
			sidebar(){
				return !this.$route.path.match('/marketplace/product')
			}
		}
	}
</script>
